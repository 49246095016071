import {
    capitalize,
    formDataOutput,
    handleGenerateLink,
    handleMailTo,
    updateFormInitialValuesIfExist,
} from './Utils';
import html2canvas from 'html2canvas';
import { getPageName } from './SiteHelpers';

export const getData = (
    accordionFormId,
    data,
    defaultTitle,
    pageData,
    user,
    includeTotal,
    includeSubjectField = false,
    includeTitle = false,
    headingOutputFormat,
    includeDynamicData,
) => {
    const customComponentData = data?.custom_component_data || {};

    const dynamicData = includeDynamicData && data ? data.data : null;

    const initialValues = pageData?.[accordionFormId]?.formInitialValues || {};

    const initialValueModification = updateFormInitialValuesIfExist(
        initialValues,

        user,
        defaultTitle,
        data?.custom_component_data?.subject ||
            initialValues?.['subject'] ||
            '',
        (data?.data && data?.data[0]?.amount) || initialValues?.['amount'],
    );

    // Combine all sources of data, with customComponentData taking precedence
    const combinedData = {
        ...initialValueModification,
        ...dynamicData,
        ...customComponentData,
    };
    const hasSubjectField = 'subject' in combinedData;
    const seperator = hasSubjectField ? '\n\n' : '\n';
    const excludedFields = [
        ...(hasSubjectField && !includeSubjectField ? ['subject'] : []),
        'id',
        'user_id',
        'form_id',
        'data',
        'created_at',
        'updated_at',
    ];

    // Check if there's any data to process
    if (Object.keys(combinedData).length > 0) {
        const result = formDataOutput(
            combinedData,
            excludedFields,
            seperator,
            includeTotal,
            hasSubjectField,
            includeTitle ? defaultTitle : '',
            data,
            headingOutputFormat,
        );

        return result;
    } else {
        return null;
    }
};

export const handleEmail = (
    accordionFormId,
    data,
    defaultTitle,
    pageData,
    user,
    defaultSubjects, // Custom Subject
    includeTotal,
    isCustomSubject, // if subject field is there and you need its value in subject of email then you can pass this true from your config.
    headingOutputFormat,
    pageName,
    includeDynamicData,
) => {
    const userName = user && user.name ? user.name : '';
    let subject;
    let accordionData = getData(
        accordionFormId,
        data,
        defaultTitle,
        pageData,
        user,
        includeTotal,
        false,
        false,
        headingOutputFormat,
        includeDynamicData,
    );
    let body = '';
    const subjectFieldValue =
        data?.custom_component_data?.subject ??
        pageData?.[accordionFormId]?.formInitialValues?.subject;

    subject = isCustomSubject
        ? subjectFieldValue
        : `${subjectFieldValue ? subjectFieldValue : defaultTitle} ${
              defaultSubjects || ''
          }from ${userName} Generated by Toolkit.law`;
    const accordionName = capitalize(defaultTitle);
    const accordionLink = handleGenerateLink(accordionName);

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const page = pageName ? pageName : capitalize(urlParts.pop());
    const section = capitalize(urlParts.pop());
    body = subjectFieldValue
        ? ''
        : `Please see my below ${defaultTitle} information. \n\n`;

    if (accordionData) {
        body += accordionData;
        body += '\n\n';
    }

    body += subjectFieldValue
        ? ''
        : `This message was generated by the ${defaultTitle} in the ${page} Page in the ${section} Section  of the Toolkit.law. For more information see ${accordionLink}.`;

    // Check if body is empty
    if (!body) {
        console.log('Please fill all required fields.');
        return;
    }

    // Reset error message if body is not empty
    // setErrorMessage('');

    handleMailTo(subject, body);
};
// Utility function to format field names as labels
const formatLabel = (label) => {
    return label
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

/**
 * Renders the action table data in a formatted string
 * @param {Array<Object>} data Action table data
 * @returns {String} Formatted string of action table data
 */
export const renderActionTableData = (data) => {
    /**
     * Maps each row of the action table data to a formatted string
     * @param {Object} row Row of action table data
     * @param {Number} idx Index of the row
     * @returns {String} Formatted string of the row
     */
    console.log('enter into render actionTable data');
    const rows = data
        .map((row, idx) => {
            /**
             * Gets the entries of the row, excluding fields with '_number' or 'number' in the name
             */
            const entries = Object.entries(row);

            // Filter out fields with '_number' or 'number' in the name
            // Check if there are exactly two fields
            const filteredEntries = entries.filter(
                ([field]) =>
                    !field.includes('_number') && !field.includes('number'),
            );

            // Check if there are exactly two fields
            // Format output based on the number of filtered entries
            if (filteredEntries.length === 1) {
                /**
                 * For single entry, format the output to include the field name and value, with a single new line
                 */
                const [field, value] = filteredEntries[0];
                return `${formatLabel(field)} ${String(idx + 1).padStart(
                    2,
                    '0',
                )}: ${value}\n`; // Format for single entry with single new line
            } else {
                /**
                 * For other cases, keep the original format with double new lines
                 */
                // For other cases, keep the original format with double new lines
                return (
                    entries
                        .map(([field, value]) => {
                            return `${formatLabel(field)}: ${value}`;
                        })
                        .join('\n') + '\n\n'
                );
            }
        })
        .join('');

    return rows;
};

export const replacePlaceholders = (
    text,
    data,
    defaultTitle = '',
    index = '',
) => {
    const pageName = getPageName();
    return text.replace(/{{(.*?)}}/g, (_, key) => {
        const trimmedKey = key.trim();
        if (trimmedKey === 'custom_component_data') {
            if (data?.custom_component_data?.data?.length) {
                return renderActionTableData(data?.custom_component_data?.data);
            }
            return ''; // Return empty if no data is available
        } else if (trimmedKey === 'all_data') {
            const divId = `${defaultTitle}-card-${index}`;
            const outputElement = document.getElementById(divId);
            return outputElement?.innerText || '';
        } else if (trimmedKey === 'page_name') {
            return pageName;
        } else if (trimmedKey === 'defaultTitle') {
            return defaultTitle;
        } else {
            return data[trimmedKey] || '';
        }
    });
};

// Send custom email with form data
export const sendCustomEmailWithFormsData = async (
    formsData,
    emailData,
    index,
    defaultTitle,
    staticField = false,
) => {
    const data = staticField
        ? Object.entries(formsData).find(
              ([key, value]) =>
                  key.endsWith(`_${index}`) && value?.custom_component_data,
          )?.[1].custom_component_data || {}
        : formsData[index] || {};
    if (!data) {
        console.error('No data found for this index.');
        return; // Exit early if no data found
    }
    const subject = replacePlaceholders(
        emailData.subject,
        data,
        defaultTitle,
        index,
    );
    const emailBody = replacePlaceholders(
        emailData.emailBody,
        data,
        defaultTitle,
        index,
    );
    handleMailTo(subject, emailBody);
};

const generateCanvas = async (isCustomComponentPdf) => {
    try {
        if (!isCustomComponentPdf) throw new Error('reference is null');

        return await html2canvas(isCustomComponentPdf);
    } catch (error) {
        console.error(error);
    }
};

export const handleCustomComponentEmail = async (
    isCustomComponentPdf,
    emailData,
) => {
    if (isCustomComponentPdf) {
        const canvas = await generateCanvas(isCustomComponentPdf);
        if (!canvas) return;
        canvas.toBlob((blob) => {
            const item = new ClipboardItem({ 'image/png': blob });
            navigator.clipboard.write([item]).then(() => {
                console.log('Image Copied');
            });
        });
    }
    handleMailTo(emailData.subject, emailData.body);
};
