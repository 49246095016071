import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { asBlob } from 'html-docx-js-typescript-papersize-thenn';

import { formatNumber, generateSlug } from './SiteHelpers';
import { renderActionTableData } from './email';

// Register fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function blobToURL(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}

export async function fileToBlob(file, handleUpdate) {
    const { content, size } = file;
    let chunks = [];
    let i = 0;
    const totalCount = Math.round(size / 250000);

    for await (const chunk of content) {
        if (handleUpdate) {
            handleUpdate(i, totalCount);
        }
        chunks.push(chunk);
        i++;
    }
    // eslint-disable-next-line no-undef
    return new Blob(chunks);
}

export function downloadURI(uri, name) {
    var link = document.createElement('a');
    link.download = `${name}.pdf`;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function transformResponse(data, keyName) {
    const object = data.reduce((acc, item) => {
        acc[item[keyName]] = item;
        return acc;
    }, {});
    return object;
}

export function doesObjectExistWithValue(array, propertyName, value) {
    if (array === null) {
        return false; // or handle the null case in a way that makes sense for your application
    }
    return array.some((item) => item[propertyName] === value);
}

export const handlePDFGenerator = (moreFields, form, userName) => {
    // Define document content
    const documentDefinition = {
        content: [
            // Loop through moreFields data
            ...moreFields.map((entry, index) => {
                // Exclude specific fields
                const filteredEntry = Object.fromEntries(
                    Object.entries(entry).filter(
                        ([key]) =>
                            ![
                                'id',
                                'form_id',
                                'notepad',
                                'user_id',
                                'created_at',
                                'updated_at',
                            ].includes(key),
                    ),
                );

                // Rename the key "heading" to "name"
                if (filteredEntry.hasOwnProperty('heading')) {
                    filteredEntry.name = filteredEntry.heading;
                    delete filteredEntry.heading;
                }

                // Format field names (remove underscores and capitalize)
                const formattedEntry = Object.fromEntries(
                    Object.entries(filteredEntry).map(([key, value]) => [
                        formatFieldName(key),
                        value,
                    ]),
                );

                return [
                    {
                        table: {
                            widths: ['auto', '*'],
                            body: [
                                ...Object.entries(formattedEntry).map(
                                    ([key, value]) => [key, value],
                                ),
                            ],
                        },
                    },
                    { text: '\n\n' },
                ];
            }),

            form.notepad !== null
                ? [
                      {
                          table: {
                              widths: ['auto', '*'],
                              body: [['Notepad', removeHtmlTags(form.notepad)]],
                          },
                      },
                      { text: '\n\n' }, // Add space
                  ]
                : [], // Add an empty array if notepad is null
        ],

        footer: function (currentPage, pageCount) {
            return {
                text: `Page ${currentPage}`,
                alignment: 'right',
                fontSize: 8,
                margin: [0, 2, 20, 0],
            };
        },
    };

    // Function to format field names
    function formatFieldName(fieldName) {
        return fieldName
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (match) => match.toUpperCase());
    }

    // Function to remove HTML tags
    function removeHtmlTags(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    }

    const modifiedUsername = userName.replace(/ /g, '_');
    pdfMake
        .createPdf(documentDefinition)
        .download(`toolkit-law-${modifiedUsername}`);
};

/**
 * Capitalizes the first letter of each word in a given string.
 * Handles words separated by spaces or hyphens.
 *
 * @param {string} words - The input string to be capitalized.
 * @returns {string} The capitalized string.
 *
 * @example
 * capitalize("it-manager"); // returns "It Manager"
 * capitalize("tools"); // returns "Tools"
 * capitalize("multi word string"); // returns "Multi Word String"
 * capitalize("multiple-words-separated-by-hyphens"); // returns "Multiple Words Separated By Hyphens"
 * capitalize("audio-editor"); // returns "Audio Editor"
 */
export function capitalize(words) {
    var separateWords = words.toLowerCase().split(/[\s-]+/);
    for (var i = 0; i < separateWords.length; i++) {
        separateWords[i] =
            separateWords[i].charAt(0).toUpperCase() +
            separateWords[i].substring(1);
    }
    return separateWords.join(' ');
}

export const handleDOCGenerator = async (defaultTitle, form, userName) => {
    const excludedFields = [
        'form_id',
        'user_id',
        'id',
        'data',
        'created_at',
        'updated_at',
    ];

    // Check if form is an object, otherwise return an error
    if (typeof form !== 'object' || form === null) {
        console.error('Form is not an object or is null.');
        return;
    }

    const filteredForm = Object.keys(form)
        .filter((key) => !excludedFields.includes(key))
        .reduce((obj, key) => {
            obj[key] = form[key];
            return obj;
        }, {});

    const resumeHeading = `${capitalize(userName)} Resume`;

    const notNullValues = Object.entries(filteredForm)
        .filter(([key, value]) => value !== null && value !== undefined)
        .map(([key, value]) => ({ key, value }));

    // Create an HTML string representing the document
    const htmlString = `
        <html>
            <head>
                <title>${defaultTitle}</title>
            </head>
            <body>
               <h1 style="text-align:center;margin-bottom:1em">${
                   defaultTitle === 'Resume Data'
                       ? resumeHeading
                       : capitalize(defaultTitle)
               }</h1>
                <div 
                    style="border: 2px solid #dee2e6; border-radius: 0.375rem; margin-bottom: 1rem; background-color: #f2f2f2;">
                ${notNullValues
                    .map(
                        ({ key, value }) =>
                            `<p style=" padding: 10px;"><strong>${capitalize(
                                key,
                            )}:</strong> ${value}</p>`,
                    )
                    .join('')}
                    </div>
            </body>
        </html>
    `;

    // Generate the DOCX file content
    const docAsBlob = await asBlob(htmlString, {
        orientation: 'portrait',
        margins: { top: 100 },
    });

    saveAs(
        docAsBlob,
        `toolkit.law-${capitalize(userName)}-${capitalize(defaultTitle)}.docx`,
    );
};
export const sortArrayByKey = (array, key) => {
    return array.sort((a, b) => {
        const valueA = a[key];
        const valueB = b[key];

        // You may need to handle cases where the key is not present in some objects
        if (valueA < valueB) {
            return -1;
        } else if (valueA > valueB) {
            return 1;
        } else {
            return 0;
        }
    });
};

export const downloadAsWpd = (text, fileName) => {
    const textToDownload = text;

    // Create a new Blob with the text content
    const blob = new Blob([textToDownload], {
        type: 'application/wordperfect',
    });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${fileName}.wpd`;

    // Append the link to the body
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
};

export const downloadAsText = (textContent, fileName) => {
    const blob = new Blob([textContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};

export const downloadAsDocx = (text, fileName) => {
    const textToDownload = text;

    const blob = new Blob([textToDownload], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${fileName}.docx`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export const downloadHtmlToDocx = (content, fileName) => {
    const blob = new Blob([content], { type: 'application/msword' });

    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const formatNumberToAmount = (number) => {
    return (
        number && number?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, ', ')
    );
};

export const sortComponents = (components) => {
    return components.slice().sort((a, b) => {
        const titleA = a.props.defaultTitle.toUpperCase();
        const titleB = b.props.defaultTitle.toUpperCase();
        return titleA.localeCompare(titleB);
    });
};

export function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * @description Determines if the environment is production or not.
 * @returns {boolean} - Returns true for production, false for development or local.
 */
export function checkEnv() {
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const environment = process.env.REACT_APP_ENV || '';

    // If the API URL contains 'dev' or the environment is not 'production', return false (development)
    return !(apiUrl.includes('dev') || environment !== 'production');
}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

// Function to update paragraphs with the selected subject
function updateParagraphs(formInitialValues, user, title, subject, amount) {
    const defaultIntroduction =
        formInitialValues['introduction_paragraph'] || '';
    const defaultDetails = formInitialValues['details_paragraph'] || '';

    const defaultConclusion = formInitialValues['conclusion_paragraph'] || '';

    // Replace placeholders with the subject and title
    return {
        introduction_paragraph: defaultIntroduction
            .replace(/{title}/g, title)
            .replace(/{subject}/g, subject)
            .replace(/{userName}/g, user && user.name ? user.name : ''),
        details_paragraph: defaultDetails
            ? defaultDetails
                  .replace(/{title}/g, title)
                  .replace(/{subject}/g, subject)
                  .replace(/{amount}/g, amount)
            : '',
        conclusion_paragraph: defaultConclusion.replace(/{title}/g, title),
    };
}

export function updateFormInitialValuesIfExist(
    formInitialValues,
    user,
    title,
    subject,
    amount,
) {
    const updatedFormInitialValues = { ...formInitialValues };
    const fieldsToUpdate = [
        'introduction_paragraph',
        'details_paragraph',
        'conclusion_paragraph',
    ];

    fieldsToUpdate.forEach((field) => {
        if (updatedFormInitialValues.hasOwnProperty(field)) {
            const updatedParagraphs = updateParagraphs(
                updatedFormInitialValues,
                user,
                title,
                subject,
                amount,
            );
            updatedFormInitialValues[field] = updatedParagraphs[field];
        }
    });

    // Optionally update the adr_professional field
    if (updatedFormInitialValues.hasOwnProperty('adr_professional')) {
        updatedFormInitialValues['adr_professional'] =
            user && user.name ? user.name : '';
    }

    return updatedFormInitialValues;
}

export const formDataOutput = (
    combinedData,
    excludedFields,
    separator,
    includeTotal,
    hasSubjectField = false, // Control whether to hide null/undefined fields
    includeTitle,
    data,
    headingOutputFormat = false, // Control heading output format
) => {
    // Check if there's any data to process
    let dataArray = [];
    console.log(combinedData);
    if (Array.isArray(combinedData)) {
        // Filter out null and undefined items
        dataArray = combinedData.filter(
            (item) => item !== null && item !== undefined,
        );
    } else if (typeof combinedData === 'object' && combinedData !== null) {
        // Convert object keys to array of objects
        dataArray = Object.keys(combinedData)
            .filter(
                (fieldName) =>
                    combinedData[fieldName] !== null &&
                    combinedData[fieldName] !== undefined &&
                    !excludedFields.includes(fieldName),
            )
            .map((fieldName) => {
                const fieldValue = combinedData[fieldName];
                return hasSubjectField
                    ? fieldValue
                    : {
                          fieldName: toTitleCase(fieldName),
                          value: isNaN(fieldValue)
                              ? fieldValue
                              : formatNumber(fieldValue),
                      };
            });
    } else {
        // Handle other types or empty data
        return [];
    }

    // Include total calculation if specified
    if (includeTotal) {
        const total = Object.values(combinedData).reduce(
            (acc, val) => acc + (parseFloat(val) || 0),
            0,
        );

        dataArray.push({
            fieldName: 'Total',
            value: isNaN(total) ? total : formatNumber(total),
        });
    }

    // Include title at the beginning if specified
    if (includeTitle) {
        dataArray.unshift({ fieldName: 'Title', value: includeTitle });
    }

    // Filter out fields where value is null or undefined, only if hasSubjectField is false
    let filteredDataArray = hasSubjectField
        ? dataArray // Do not filter if hasSubjectField is true
        : dataArray.filter(
              (item) => item?.value !== null && item?.value !== undefined,
          );

    // due to filtering the fields order disrupt, need to sort fields
    if (includeTotal) {
        filteredDataArray = filteredDataArray.sort((a, b) => {
            if (a.fieldName?.trim() === 'Total') return 1;
            if (b.fieldName?.trim() === 'Total') return -1;
            return a.fieldName?.trim().localeCompare(b.fieldName?.trim());
        });
    }

    let result;
    if (headingOutputFormat) {
        // Format with heading style
        result = filteredDataArray
            .map((item) => {
                if (
                    typeof item === 'object' &&
                    item.value &&
                    typeof item.value === 'object'
                ) {
                    // Handle nested objects appropriately
                    return `${item.value.name}`;
                } else if (typeof item === 'object') {
                    // Convert other objects to strings if needed
                    return `${item.fieldName}:\n${item.value}\n`;
                } else {
                    // Convert other types to strings
                    return item;
                }
            })
            .join('\n'); // Join with a newline to create a blank line between fields
    } else {
        // Original format with separator
        result = filteredDataArray
            .map((item) => {
                if (
                    typeof item === 'object' &&
                    item.value &&
                    typeof item.value === 'object'
                ) {
                    // Handle nested objects appropriately
                    if (item.value.vCard) {
                        return `${item.value.name}`;
                    } else
                        return Object.entries(item.value)
                            .map(([key, value]) =>
                                key === 'custom_component_data'
                                    ? renderActionTableData(value.data)
                                    : `${toTitleCase(key)}: ${value}`,
                            )
                            .join('\n');
                } else if (typeof item === 'object') {
                    // Convert other objects to strings if needed
                    return `${item.fieldName}: ${item.value}`;
                } else {
                    // Convert other types to strings
                    return item;
                }
            })
            .join(
                arrayHasObjects(filteredDataArray)
                    ? separator + separator
                    : separator,
            );

        function arrayHasObjects(arr) {
            // Check if array contains any objects
            return arr.some((item) => typeof item === 'object');
        }
    }
    console.log(filteredDataArray);

    filteredDataArray = filteredDataArray.map((item) => ({
        ...item,
        value: item.value === 'NaN' ? 'Yes' : item.value,
    }));

    return result ? result.replace(/NaN/g, 'Yes') : filteredDataArray;
};

export const toTitleCase = (str) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Handle camelCase
        .replace(/[_\.\-]/g, ' ') // Replace underscores and dots with spaces
        .toLowerCase() // Convert to lowercase for uniformity
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

export const customEmailBodyFormat = (fields) => {
    return fields
        ?.map((item) => {
            return `${item.fieldName}:\n${item.value?.trim()}`;
        })
        ?.join('\n\n');
};

/**
 * GenerateLink is a function that creates a new URL with updated query parameters.
 * Specifically, it updates or adds the 'accordion' parameter with a slug generated from the provided name.
 *
 * @param {string} accordionName - The name to generate a slug for and set as the 'accordion' query parameter.
 * @returns {string} - The new URL with the updated 'accordion' query parameter.
 */
export const GenerateLink = (accordionName) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('accordion');
    urlParams.set('accordion', accordionName);
    return `${window.location.origin}${
        window.location.pathname
    }?${urlParams.toString()}`;
};

/**
 * handleGenerateFeedback is a function that constructs an email feedback link with a subject and body.
 * The subject includes details about the current section, page, and accordion name, and the body includes a link to the specific accordion.
 *
 * @param {string} accordionName - The name of the accordion for which feedback is being generated.
 *
 * Usage:
 *  handleGenerateFeedback("Accordion Name")
 * This will open an email draft in the default email client with the subject
 */
export const handleGenerateFeedback = (accordionName, isPage = false) => {
    const link = isPage
        ? window.location.href
        : handleGenerateLink(accordionName);
    const emailSubject = 'Toolkit.law Feedback';
    const bodyMessage = `I am sharing with you feedback on ${link}.\n\n\n`;
    handleMailTo(emailSubject, bodyMessage, 'feedback@toolkit.law');
};

/**
 * Generates a link to a specific accordion, copies it to the clipboard, and returns the link.
 *
 * @param {string} accordionName - The name of the accordion to generate the link for.
 * @param {function} setCopySuccess - A function to set the success state of the copy action.
 * @param {boolean} [isSlug=false] - Optional parameter indicating if the provided accordionName is already a slug.
 * @returns {string} - The generated link to the specific accordion.
 *
 * Usage:
 *  handleGenerateLink("Accordion Name", setCopySuccess, false)
 * This will generate a link to the "Accordion Name" accordion, copy it to the clipboard, set the copy success state, and return the link.
 */
export const handleGenerateLink = (
    accordionName,
    setCopySuccess,
    isSlug = false,
    isPage = false,
) => {
    let link = '';
    if (isPage) {
        link = window.location.href;
    } else {
        const accordionSlug = isSlug
            ? accordionName
            : generateSlug(accordionName);
        link = GenerateLink(accordionSlug);
    }
    navigator.clipboard
        .writeText(link)
        .then(() => {
            setCopySuccess?.(true);

            // Clear the "copy success" message after a certain duration (e.g., 2 seconds)
            setTimeout(() => {
                setCopySuccess?.(false);
            }, 2000);
        })
        .catch((err) => {
            console.error('Unable to copy to clipboard', err);
        });
    return link;
};

export function downloadFile(data, filename, mime, bom) {
    // Create the Blob data, prepend BOM if provided
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, {
        type: mime || 'application/octet-stream',
    });

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround to save blob directly
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // Create a URL for the blob
        var blobURL =
            window.URL && window.URL.createObjectURL
                ? window.URL.createObjectURL(blob)
                : window.webkitURL.createObjectURL(blob);

        // Create an invisible anchor element
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        // Handle Safari pop-up blocking by setting target to _blank if download attribute is not supported
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        // Append anchor to the document body and trigger download
        document.body.appendChild(tempLink);
        tempLink.click();

        // Remove the anchor and revoke the blob URL to free up resources
        setTimeout(function () {
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }, 200);
    }
}

export function handleMailTo(subject, body, email = '') {
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        subject,
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink, '_blank');
}

export const handleDefaultEmail = (accordionName, isPage = false) => {
    const weblink = isPage
        ? window.location.href
        : handleGenerateLink(accordionName);
    const emailSubject = `Sharing a Toolkit.law Weblink`;
    const bodyMessage = `I am sharing with you the following Toolkit.law weblink:\n\n${weblink}\n\nFor more information, see https://www.toolkit.law to sign up for your free 6-month Trial Account. \n\n`;
    handleMailTo(emailSubject, bodyMessage);
};
export const handlePageEmail = (
    pageName,
    defaultTitle,
    emailData,
    accordionFormId,
    pageData,
    user,
    includeDynamicData,
) => {
    const getData = (
        accordionFormId,
        data,
        defaultTitle,
        pageData,

        includeTotal,
        includeSubjectField = false,
        includeTitle = false,
        customEmailBodyFormat,
        headingOutputFormat,
        includeDynamicData,
    ) => {
        const customComponentData = data?.custom_component_data || {};
        const dynamicData = includeDynamicData && data ? data.data : null;
        const initialValues =
            pageData?.[accordionFormId]?.formInitialValues || {};
        const combinedData = {
            ...initialValues,
            ...dynamicData,
            ...customComponentData,
        };
        const hasSubjectField = 'subject' in combinedData;
        const separator = hasSubjectField ? '\n\n' : '\n';
        const excludedFields = [
            ...(hasSubjectField && !includeSubjectField ? ['subject'] : []),
            'id',
            'user_id',
            'form_id',
            'data',
            'created_at',
            'updated_at',
        ];
        const result = formDataOutput(
            combinedData,
            excludedFields,
            separator,
            includeTotal,
            hasSubjectField,
            includeTitle ? defaultTitle : '',
            customEmailBodyFormat,
            data,
            headingOutputFormat,
        );

        return result || null;
    };

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const page = pageName ? pageName : capitalize(urlParts.pop());
    const section = capitalize(urlParts.pop());

    const subject = `${page} Information Generated by Toolkit.law`;

    let body = `Please see the below or attached information from the ${page} page in the ${section} section of Toolkit.law. This resource can be located at ${currentUrl}. \n
         [paste information we copied here] 
        
    `;
    const accordionData = getData(
        accordionFormId,
        emailData,
        defaultTitle,
        pageData,
        user,
        includeDynamicData,
    );

    if (accordionData) {
        body += accordionData;
        body += '\n';
    }

    body += `Toolkit.law features 1,500+ legal tools, calculators, and utilities; and includes 24 hours of global continuing legal education (CLE). Sign up for the free 6-month Trial Account at https://www.toolkit.law.
    `;
    handleMailTo(subject, body);
};

/**
 * Converts a plural word to its singular form.
 *
 * This function handles the following pluralization rules:
 * - Words ending in 'ies' are converted by replacing 'ies' with 'y'.
 * - Words ending in 's' are converted by removing the trailing 's'.
 * - Words not ending in 's' are returned as they are.
 *
 * @param {string} word - The plural word to be converted to singular.
 * @returns {string} - The singular form of the word.
 */
export const pluralToSingular = (word) => {
    if (!word) {
        return '';
    }
    if (word.endsWith('ies')) {
        return word.slice(0, -3) + 'y';
    } else if (word.endsWith('s')) {
        return word.slice(0, -1);
    }
    return word;
};

export const getManifestUrl = () => {
    const environment = process.env.REACT_APP_ENV || 'production';
    const baseUrl = process.env.REACT_APP_SITE_URL;

    // Default manifest URL for production
    let manifestUrl = `${baseUrl}/manifest.json`;

    // Adjust the manifest URL based on the environment
    if (environment === 'development') {
        manifestUrl = `${baseUrl}/manifest/manifest.dev.json`;
    } else if (environment === 'test') {
        manifestUrl = `${baseUrl}/manifest/manifest.test.json`;
    } else if (environment === 'local') {
        manifestUrl = `${baseUrl}/manifest/manifest.test.json`;
    }

    return manifestUrl;
};
